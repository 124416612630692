import { Link } from "react-router-dom"

import "../css/About.css"
import Meridia from "../images/meridia.jpg"
import Ajani from "../images/ajani.jpg"
import gaylovers from "../images/gaylovers.jpg"
import alien from "../gifs/alien.gif"
import pixelprof from "../images/pixel.jpg"

import bandcamplogo from "../svgs/bandcamp.png"
import soundcloudlogo from "../svgs/soundcloud.svg"
import spotifylogo from "../svgs/spotify.png"
import neocitieslogo from "../svgs/neocities.svg"

import soc from "../images/soc.jpg"
import eb from "../images/empathybox.jpg"
import hor from "../images/hor.jpg"
import cas from "../images/cas.jpg"
import test from "../images/sectiontest.png"
import johnrscard from "../images/JohnAboutMe.png"

import AlbumCard from "../../components/AlbumCard"


const AboutPage =() => {
    return(
        <div className="about_me_container">
            <div className="ls_aboutme">
                <img src={pixelprof} alt="pixel prof pic"/>
            </div>
            <div className="rs_aboutme">
                <div className="simple_container">
                    <div className="subtitle_2">About Me!</div>
                    <img src={johnrscard} alt="testing"/>
                </div>
                <div className="text_1">
                    I am a multimedia artist/developer/whatever from San Antonio TX that does a lot of stuff. I have too 
                    many hobbies so I made this website to manage them all awkwardly. I love music, art, old video games. 
                    I enjoy drinking beer and hanging out with my friends.<br/>
                </div>
                <hr/>
                <div className="text_1">
                    I have two beautiful kitties named Ajani and Meridia. They keep me up late at night and wake me up 
                    early in the morning. I wake up with random scratch marks. If I miss a deadline, blame them.<br/>
                    <div style={{"display": "flex", "justifyContent": "center"}}>
                        <div className="cat_photo_container">
                            <img src={alien} className="layeredgif" alt="Alien red circle?"/>
                            <img src={Ajani} className="cat_photo" alt="Ajani cat"/><br/>
                            Ajani
                        </div>
                        <div className="cat_photo_container">
                            <img src={Meridia} className="cat_photo" alt="Meridia cat"/><br/>
                            Meridia
                        </div>
                    </div>
                </div>
                <div style={{"display": "flex", "justifyContent": "center"}}>
                    <img src={gaylovers} style={{"padding": "2vh"}} alt="gay lovers"/>
                </div>
                <div className="text_1" style={{"textAlign": "center"}}>
                    Here they are being gay :)
                </div>
                <hr/>
                <div className="simple_container">
                    <div className="subtitle_2">DJ KLAPTRAP</div>
                    <img src={test} alt="testing"/>
                </div>
                <div className="text_2">
                    Experimental electronic, Breakcore, Drill and Bass music alias.
                    <div className="subtitle_2">Releases</div>
                    <div className="klaptrap_releases_container">
                        <AlbumCard  title="Cardboard and Silverfish"
                                    art={cas}
                                    release_date="May 12, 2023"
                                    link="https://djklaptrap.bandcamp.com/album/cardboard-and-silverfish"
                        />
                        <AlbumCard  title="Hidden Object Rave EP"
                                    art={hor}
                                    release_date="May 20, 2022"
                                    link="https://djklaptrap.bandcamp.com/album/hidden-object-rave-ep"
                        />
                        <AlbumCard  title="empathy box"
                                    art={eb}
                                    release_date="January 1, 2022"
                                    link="https://djklaptrap.bandcamp.com/album/empathy-box"
                        />
                        <AlbumCard  title="Stream of Consciousness EP"
                                    art={soc}
                                    release_date="November 6, 2020"
                                    link="https://djklaptrap.bandcamp.com/album/stream-of-consciousness-ep"
                        />
                    </div>
                </div><br/>
                <div className="klaptrap_button_container">
                    <Link to="https://djklaptrap.bandcamp.com/" target="_blank">
                        <button className="klaptrap_button">
                            <img src={bandcamplogo} className="img_logo" alt="bandcamp_logo"/>
                        </button>
                    </Link>
                    <Link to="https://soundcloud.com/djklaptrap" target="_blank">
                        <button className="klaptrap_button">
                            <img src={soundcloudlogo} className="img_logo" alt="soundcloud_logo"/>
                        </button>
                    </Link>
                    <Link to="https://open.spotify.com/artist/7h8sqeXI35MLkLQgoTebL0?si=sUVYnU-qQsmH9qnAZnKG0w" target="_blank">
                        <button className="klaptrap_button">
                            <img src={spotifylogo} className="img_logo" alt="spotify_logo"/>
                        </button>
                    </Link>
                </div>
                <div style={{"textAlign": "right"}}>
                    Check out my legacy website --&gt;
                    <Link to="https://djkl4ptr4p.neocities.org/" target="_blank">
                        <button className="klaptrap_button">
                            <img src={neocitieslogo} className="img_logo" alt="bandcamp_logo"/>
                        </button>
                    </Link>
                </div>
                <hr/>
            </div>
        </div>
    );
}

export default AboutPage;