import { getRequest } from "./serviceUtils"
const apiUrl = "https://api.wrinklykong.com/api"

export const get_all_projects = async () => {
    return getRequest(`${apiUrl}/Project/`)
}

export const get_project_by_id = async (id) => {
    return getRequest(`${apiUrl}/Project/id/${id}`);
}

export const get_project_by_num = async (num) => {
    return getRequest(`${apiUrl}/Project/num/${num}`)
}

// From the Beer MR
// export const getPostsFromQuery = async (jsonQuery) => {
//     return getRequest(`${apiUrl}/Beer/query/${jsonQuery}`)
// }
// 
// export const getRecentPosts = async () => {
//     return getRequest(`${apiUrl}/Beer/recent`)
// }
// 
// export const getPostsPage = async (page) => {
//     return getRequest(`${apiUrl}/Beer/recent/${page}`)
// }