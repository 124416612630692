import { Link } from "react-router-dom";

import "../css/Resume.css"

import JohnProfPic from "../images/profpic.jpg"
import ResumeExperienceObject from "../../components/ResumeExperienceObject";
import ResumeProjectObject from "../../components/ResumeProjectObject";
import { about_me_contents } from "../../components/Utils";

import githublogo from "../svgs/git.svg"
import lilogo from "../svgs/li.png"
import downloadbutton from "../svgs/download.svg"
import resume from "../files/Resume.pdf"

const ResumePage =() => {
    return(
        <div className="resume_page_container">
            <div className="container_container">
                <div className="left_side_container">
                    <img src={JohnProfPic} alt="John Jones' face" className="john_photo"></img>
                    <div className="resume_button_container">
                        <Link to="https://www.linkedin.com/in/john-w-jones/" target="_blank">
                            <button className="resume_button">
                                <img src={lilogo} className="img_logo" alt="linkedin logo"/>
                            </button>
                        </Link>
                        <Link to="https://github.com/wrinklykong" rel="noreferrer" target="_blank">
                            <button className="resume_button">
                                <img src={githublogo} className="img_logo" alt="github logo"/>
                            </button>
                        </Link>
                        {/* TODO: Update resume to be recent */}
                        <a href={resume} download="JohnWJonesResume_05-2024.pdf" rel="noreferrer" target="_blank">
                            <button className="resume_button">
                                <img src={downloadbutton} className="img_logo" alt="github logo"/>
                            </button>
                        </a>
                    </div>
                    <div className="skills_div">
                        <div className="skills_content">
                            <b className="section_title">Languages</b>
                            <div className="skills_list">                        
                                <div className="skill">Python</div>
                                <div className="skill">JavaScript</div>
                                <div className="skill">C</div>
                                <div className="skill">Bash</div>
                            </div>
                            <b className="section_title">Skills</b>
                            <div className="skills_list">
                                <div className="skill">CNO Development</div>
                                <div className="skill">Microcontrollers</div>
                                <div className="skill">Soldering</div>
                                <div className="skill">Wireshark</div>
                                <div className="skill">Linux</div>
                            </div>
                            <b className="section_title">Interests</b>
                            <div className="skills_list">
                                <div className="skill">Green 5G</div>
                                <div className="skill">Embedded Systems</div>
                                <div className="skill">Reverse Engineering</div>
                                <div className="skill">Vulnerability Research</div>
                            </div>
                            <b className="section_title">Hobbies</b>
                            <div className="skills_list">
                                <div className="skill">DJing</div>
                                <div className="skill">Music Production</div>
                                <div className="skill">Game Development</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right_side_container">
                    <div className="objective_div">
                        <b className="section_title">About Me</b>
                        <div className="objective">
                            {about_me_contents}
                        </div>
                    </div>
                    <div className="education_div">
                        <b className="section_title">Education</b>
                        <ResumeExperienceObject job_title="University of Texas at San Antonio" 
                                                company="1 UTSA Circle"
                                                date="August 2020 - May 2023"
                                                desc={(
                                                    <div className="description_div">
                                                        ● Bachelor of Science (B.S.), Computer Science<br/>
                                                        ● Cum Laude, 3.73 GPA
                                                    </div>
                                                )}
                                                location="San Antonio, TX"/>
                    </div>
                    <div className="job_experience_div">
                        <b className="section_title">Experience</b>
                        <ResumeExperienceObject job_title="Embedded Systems Reverse Engineer" 
                                                company="iCR, inc."
                                                date="May 2023 - Present" 
                                                desc={(
                                                    <div className="description_div">
                                                        ● Ported network tools for use in a greater framework<br/>
                                                        ● Wrote automated tests which validate integration requirements to support continuous deployment
                                                    </div>
                                                )}
                                                location="San Antonio, TX"/>
                    </div>
                    <div className="projects_div">
                        <b className="section_title">Projects</b>
                        <ResumeProjectObject    project_title="MV Generator"
                                                date="June 2024"
                                                languages="Python"
                                                desc={(
                                                    <div className="description_div">
                                                        ● Scripted an FFmpeg wrapper which manages music and mass-generates videos for music files<br/>
                                                        ● Used for uploading vinyl rips to YouTube (youtube.com/@wrnklykng-dm4ti)
                                                    </div>
                                                )}/>
                        <ResumeProjectObject    project_title="LoRAPTOR"
                                                date="May 2023"
                                                languages="C, Network Programming"
                                                desc={(
                                                    <div className="description_div">
                                                        ● Designed layer 2/3 network architecture designed for use in high-density IoT areas<br/>
                                                        ● Developed on LoRa-enabled Raspberry Pi's with basic network communication protocol
                                                    </div>
                                                )}/>
                        <ResumeProjectObject    project_title="USB MIDI Controller"
                                                date="January 2023"
                                                languages="C, Microcontrollers"
                                                desc={(
                                                    <div className="description_div">
                                                        ● Designed USB plug-and-play MIDI Controller that sends music note and Control Change data to the Digital Audio Workplace (DAW)<br/>
                                                        ● Developed on a Sparkfun Pro Micro Microcontroller<br/>
                                                        ● Features three push buttons and three programmable knobs that can control up to 16 MIDI Control Change channels
                                                    </div>
                                                )}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResumePage;