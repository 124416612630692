import { useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { getLatestBeer } from "../../services/beerServices";

const LatestBeerPage = () => {

    const isLoading = useRef(true);
    const wasCalled = useRef(false);
    const loadingFalse = useRef(false);

    const navigate = useNavigate()

    async function init() {
        const beer = await getLatestBeer()
        if ( beer == null || beer.data.length != 1 ) {
            navigate("/beer/404")
        }
        else if ( beer.data[0].link == null || beer.data[0].link == undefined ) {
            navigate("/beer/404")
        }
        else {
            navigate(`/beer/${beer.data[0].link}`)
        }
        isLoading.current = false;
        loadingFalse.current = true;
    }

    useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        init();
    }, [])

    if ( isLoading.current ) {
        return (
            <p>Loading Beer...</p>
        )
    }
    else if ( loadingFalse.current ) {
        navigate("/beer/404")
    }
}

export default LatestBeerPage;