import { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { getRecentBeers, getBeerPage } from "../../services/beerServices";
import "../css/Beer.css"
import fridge from "../pixelart/fridge/fridge.png"
import tile from "../pixelart/fridge/tile.png"
import BeerInFridgeCard from "../../components/BeerInFridge";


const BeerDirectoryPage = () => {

    const [posts, setPosts] = useState();
    const [postNum, setPostNum] = useState();

    const wasCalled = useRef(false);
    const isLoading = useRef(true);
    const failedRequest = useRef(false);

    const navigate = useNavigate()

    function redirectToPageNotFound() {
        navigate("/beer/404")
    }

    async function grabRecentPosts() {
        failedRequest.current = false;
        isLoading.current = true;
        const response = await getBeerPage(0);
        if ( response != null ) {
            setPostNum(0);
        }
        else {
            failedRequest.current = true;
            setPosts(null);
        }
        setPosts(response);
        isLoading.current = false;
    }

    async function getNextResults () {
        failedRequest.current = false;
        isLoading.current = true;
        const response = await getBeerPage(postNum+1);
        if ( response != null ) {
            isLoading.current = false;
            setPosts(response);
            setPostNum( postNum+1 );
        }
        else {
            isLoading.current = false;
            failedRequest.current = true;
            setPosts(null);
        }
    }

    async function getPrevResults () {
        failedRequest.current = false;
        isLoading.current = true;
        const response = await getBeerPage(postNum-1);
        if ( response != null ) {
            isLoading.current = false;
            setPosts(response);
            setPostNum( postNum-1 );
        }
        else {
            isLoading.current = false;
            failedRequest.current = true;
            setPosts(null);
        }
    }

    // Page functionality

    function init() {
        grabRecentPosts();
    }

    useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        init();
    }, [])

    useEffect(() => {
        document.body.style.backgroundColor = "red";
    });

    if (isLoading.current) {
        // Todo: make a loading div
        return (
            <a href="loading">Loading ...</a>
            )
        }
    else if (isLoading.current !== true && failedRequest.current) {
        // TODO: Add a page that displays that an error occured
        redirectToPageNotFound()
    }
    else {
        return(
            <div className="beer_directory_container">
                <div className="BeerFrontPage">
                    <div className="BeerPage">
                        <img src={fridge} className="fridge_image"/>
                        <div className="beer_container">
                            {Array.from(new Array(posts.data.length), (e1, index) => (
                                <div className="PostCardList" key={index}>
                                    <BeerInFridgeCard   key={index}
                                                        image_link={posts.data[index].photoLink}
                                                        beer_link={posts.data[index].link}
                                                        />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="buttonFooter">
                    { postNum!==0 ?<Button className="navButton" 
                            onClick={getPrevResults} 
                            style={{display: "block"}}
                            >Prev</Button>: null}
                    { posts.data.length === 8 ?<Button className="navButton" 
                            onClick={getNextResults} 
                            style={{display: "block"}}
                            >Next</Button>: null}
                </div>
            </div>
        )
    }
}

export default BeerDirectoryPage;