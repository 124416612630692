import { Link } from "react-router-dom";
import wrinkly from "../view/images/wrinkly_image.webp"

import "../view/css/NavBar.css"

const WrinklyNavBar = () => {
    return (
        <div className="WrinklyNavBar">
            <div className="BrandBar">
                <p>
                    <img src={wrinkly} style={{height:'3vh', width:'2vh'}} alt="WKLogo"></img>
                    <b>WrinklyKong dot com!</b>
                </p>
            </div>
            <Link to={"/"}>
                <button className="nav_bar_button">Home</button>
            </Link>
            <Link to={"/cli"}>
                <button className="nav_bar_button">Terminal</button>
            </Link>
            <Link to={"/Projects"}>
                <button className="nav_bar_button">Projects</button>
            </Link>
            <Link to={"/Beer"}>
                <button className="nav_bar_button">Beer</button>
            </Link>
            <Link to={"/About"}>
                <button className="nav_bar_button">About</button>
            </Link>
            <Link to={"/Resume"}>
                <button className="nav_bar_button">Resume</button>
            </Link>
            <br/><br/>
        </div>
    )
}

export default WrinklyNavBar;