import { useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

import PostPageContainer from '../../components/PostPage';
import { getBeerByID } from "../../services/beerServices";
import "../css/BeerPost.css"


const BeerPostPage = ( props ) => {

    const [post, setPost] = useState()
    const isLoading = useRef(true);
    const wasCalled = useRef(false);

    const navigate = useNavigate()
    const { id } = useParams();

    function redirectToPageNotFound() {
        navigate("/beer/404")
    }

    async function getPost(id) {
        const result = await getBeerByID(id);
        if ( result == null || result.data.length === 0 ) {
            redirectToPageNotFound()
            return
        }
        setPost(result.data[0]);
        isLoading.current = false;
        console.log(result)
    }

    // Page functionality

    function init() {
        getPost(id)
    }

    useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        init();
    }, [])

    if (isLoading.current) {
        return(
            <p>Loading ...</p>
        )
    }
    else{
        return (
            <PostPageContainer db_results={post}/>
        )
    }
}

export default BeerPostPage;