import {BrowserRouter, Routes, Route} from "react-router-dom"
import MainPage from "./view/pages/Main"
import NotFoundPage from "./view/pages/404";
import ProjectPage from "./view/pages/Projects";
import WrinklyNavBar from "./components/wrinklyNavBar";
import WrinklyKongFooter from "./components/WrinklyKongFooter"
import AboutPage from "./view/pages/About";
import ResumePage from "./view/pages/Resume";
import TerminalPage from "./view/pages/Terminal";
import BeerDirectoryPage from "./view/pages/BeerDirectory";
import BeerPostPage from "./view/pages/BeerPost"
import BeerTierListPage from "./view/pages/BeerTierList"
import BeerHomePage from "./view/pages/BeerHome"
import MDTestPage from "./view/pages/MarkdownTest";
import LatestBeerPage from "./view/pages/LatestBeer";
import BeerNotFoundPage from "./view/pages/BeerNotFound";

function App() {
  return (
    <div className="helpme">
      <BrowserRouter>
        <WrinklyNavBar/>
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/projects" element={<ProjectPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/resume" element={<ResumePage/>} />
          <Route path="/cli" element={<TerminalPage/>} />
          <Route path="/beer" element={<BeerHomePage/>} />
          <Route path="/beerdir" element={<BeerDirectoryPage/>} />
          <Route path="/beer/:id" element={<BeerPostPage/>} />
          <Route path="/beerlist" element={<BeerTierListPage/>} />
          <Route path="/test" element={<MDTestPage/>} />
          <Route path="/beer/latest" element={<LatestBeerPage/>} />
          <Route path="/beer/404" element={<BeerNotFoundPage/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
        <WrinklyKongFooter/>
      </BrowserRouter>
    </div>
  )
}

export default App;
