import { TerminalContextProvider } from "react-terminal"
import WKTerminal from "../../components/WKTerminal";

import "../css/Default.css"

const TerminalPage =() => {
    return(
        <TerminalContextProvider>
            <WKTerminal/>
        </TerminalContextProvider>
    );
}

export default TerminalPage;