import { getRequest } from "./serviceUtils"
const apiUrl = "https://api.wrinklykong.com/api"

export const getAllBeers = async () => {
    return getRequest(`${apiUrl}/Beer/`);
}

export const getBeerByID = async (id) => {
    return getRequest(`${apiUrl}/Beer/id/${id}`);
}

export const getBeerFromQuery = async (jsonQuery) => {
    return getRequest(`${apiUrl}/Beer/query/${jsonQuery}`)
}

export const getRecentBeers = async () => {
    return getRequest(`${apiUrl}/Beer/recent`)
}

export const getBeerPage = async (page) => {
    return getRequest(`${apiUrl}/Beer/recent/${page}`)
}

export const getLatestBeer = async () => {
    return getRequest(`${apiUrl}/Beer/latest`)
}
