import { useState, useRef, useEffect } from "react";

import { get_all_projects } from "../../services/projectServices";
import ProjectCard from "../../components/ProjectCard";
import Loading from "../../components/Loading";
import LoadingFailed from "../../components/LoadinFailed";
import "../css/ProjectCard.css"
import "../css/Projects.css"

const ProjectPage = () => {

    const [projects, setProjects] = useState();

    const isLoading = useRef(true);
    const wasCalled = useRef(false);

    async function grab_projects () {
        isLoading.current = true;
        const response = await get_all_projects();
        if ( response == null ) {
            console.log("Failed to grab projects")
        }
        setProjects(response)
        isLoading.current = false;
    } 

    useEffect(() => {
        if (wasCalled.current) return;
        // console.log(wasCalled.current)
        wasCalled.current = true;
        grab_projects();
    }, [])

    if ( isLoading.current ) {
        return (
            <Loading/>
        )
    }
    else if ( ! isLoading.current && projects == null ) {
        return (
            <LoadingFailed/>
        )
    }
    else {
        return(
            <div className="projects_container">
                <div className="projects_page_details">
                    <h1>Projects</h1>
                    <p>Assorted programming projects. Click the links in each card to learn more.</p>
                </div>
                <div className="project_page_box">
                    <div className="project_card_wrapper">
                        {Array.from(new Array(projects.data.length), (e1, index) => (
                            <ProjectCard    title={projects.data[index].title}
                                            date={projects.data[index].date}
                                            desc={projects.data[index].desc}
                                            img_link={projects.data[index].photo_link}
                                            git_link={projects.data[index].git_link}
                                            oth_links={projects.data[index].other_links}
                                            label_array={projects.data[index].labels}
                                            hide={projects.data[index].hide}
                                            key={index}
                                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectPage;