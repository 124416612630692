
import "../view/css/ResumeExperienceObject.css"

const ResumeProjectObject = ({obj, project_title, languages, desc, date}) => {
    return (
        <div className="resume_object">
            <div className="resume_object_content_container">
                <div className="left_side_resume_object">
                <div className="date_div">{date}</div>
                </div>
                <div className="right_side_resume_object">
                    <b>{project_title}</b><br/>
                    <p className="experience_sub_info" >{languages}</p>
                    {desc}
                </div>
            </div>
        </div>
    )
}

export default ResumeProjectObject;