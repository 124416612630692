
import "../view/css/ResumeExperienceObject.css"

const ResumeExperienceObject = ({obj, job_title, desc, company, date, location}) => {
    return (
        <div className="resume_object">
            <div className="resume_object_content_container">
                <div className="left_side_resume_object">
                    <div className="date_div">{date}</div>
                </div>
                <div className="right_side_resume_object">
                    <b>{job_title}</b><br/>
                    <p className="experience_sub_info" >{company}; {location}</p>
                    <div className="description_div">{desc}</div>
                </div>
            </div>
        </div>
    )
}

export default ResumeExperienceObject;