import "../css/404.css"
import HeadBang from "../gifs/headbang.gif"

const NotFoundPage =() => {
    return(
        <div className="page_not_found">
            <div className="not_found_title">
                Page not Found
            </div>
            <div className="headbanggif">
                <img src={HeadBang} alt="headbanging!"/><br/>
                ((plz leave before i get a concussion))
            </div>
        </div>
    );
}

export default NotFoundPage;