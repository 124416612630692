import "../css/404.css"
import HeadBang from "../gifs/headbang.gif"

import { Link } from "react-router-dom";

const BeerNotFoundPage =() => {
    return(
        <div className="page_not_found">
            <div className="not_found_title">
                404// BEER NOT FOUND
            </div>
            <Link to={"/beer"}>
                <button className="nav_bar_button">To Beer Page</button>
            </Link>
            <div className="headbanggif">
                <img src={HeadBang} alt="headbanging!"/><br/>
                ((plz leave before i get a concussion))
            </div>
        </div>
    );
}

export default BeerNotFoundPage;