import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import 'showdown'


function PostPageContainer ({ obj, db_results }) {
    
    // Generate HTML from the RST-formatted 'contents'
    var showdown = require('showdown'),
    converter = new showdown.Converter(),
    html = converter.makeHtml(db_results['contents'])

    return(
        <div>
            <Link to={"/beer"}>
                <button className="nav_bar_button">Back to Beer</button>
            </Link>
            <div>
                <div id="beer_post_shell">
                    <div id="beer_title">
                        <b>{db_results['title']}</b>
                    </div>
                    <p>Dranked this one on: {db_results['date']}</p>
                    <div id="beer_post_details">
                        {Parser(html)}
                    </div>
                    <div id="beer_photo">
                        <img src={db_results['photoLink']} alt="beer scan" id="beer_photo_src"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostPageContainer;