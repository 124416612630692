import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import git_logo from "../view/svgs/git.svg"
import link_logo from "../view/svgs/link.svg"
import ProjectTag from "./ProjectTag"
import "../view/css/ProjectCard.css"

const ProjectCard = ({ obj, title, date, desc, img_link, git_link, oth_links, label_array, hide }) => {

    var link_buttons    = ""
    var labels          = ""

    // Hide all non-dev testing projects :)
    if ( hide !== undefined && hide === true ) {
        return ""
    }

    if ( img_link === undefined ) {
        // Default project image
        img_link = "https://storage.googleapis.com/wk-website-cdn/default-project-image.jpg"
    }

    if ( oth_links !== undefined ) {
        link_buttons =  Array.from(new Array(oth_links.length), (e1, index) => (
                            <div className="button_in_row" key={index}>
                                <Link to={oth_links[index]} target="_blank">
                                    <button className="git_button">
                                        <img src={link_logo} className="img_logo" alt="git_logo"/>
                                    </button>
                                </Link>
                            </div>
                        ))
    }

    if ( label_array !== undefined ) {
        labels =    Array.from(new Array(label_array.length), (e1, index) => (
                        <div className="project_tag">
                            <ProjectTag name={label_array[index]}/>
                        </div>
                    ))
    }

    return(
        <Card className="ProjectCard" style={{ width: '20rem' }}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <div className="project_card_contents">
                    <div className="project_card_date">
                        {date}
                    </div>
                    <img src={img_link} className="project_card_image" alt="project_image"/>
                    <div className="project_card_tags">
                        {labels}
                    </div>
                    <div className="project_card_desc">
                        {desc}
                    </div>
                    <div className="button_container">
                        <div className="button_in_row">
                            <Link to={git_link} target="_blank">
                                <button className="git_button">
                                    <img src={git_logo} className="img_logo" alt="git_logo"/>
                                </button>
                            </Link>
                        </div>
                        {link_buttons}
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ProjectCard;