import "../view/css/ProjectCard.css"

const ProjectTag = ({ obj, name}) => {

    var map_name = name
    const stylemap = {
            "Java":         ["gray", "white"],
            "research":     ["yellow", "black"],
            "default":      ["black", "white"],
            "RE":           ["red", "white"],
            "hardware":     ["green", "white"],
            "networking":   ["white", "blue"],
            "C":            [],
            "JavaScript":   [],
            "React":        [],
            "MongoDB":      [],
            "Unity":        [],
            "Linux":        ["black", "yellow"],
            "Python":       ["blue", "yellow"]
    }

    if ( stylemap[name] === undefined ) {
        map_name = "default"
    }
    return (
        <button className="label_button" style={{"backgroundColor": stylemap[map_name][0],
                                                 "color": stylemap[map_name][1]}}>
            {name}
        </button>
    )

}

export default ProjectTag;