import { useNavigate } from "react-router-dom";

import "../view/css/BeerInFridge.css"


const BeerInFridgeCard = ({obj, image_link, beer_link}) => {

    const navigate = useNavigate()

    function goToBeer() {
        navigate(`/beer/${beer_link}`)
    }

    return(
        <div className="beercontainer">
            <img src={image_link} onClick={goToBeer}/>
        </div>
    )
}

export default BeerInFridgeCard;