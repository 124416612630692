import { useNavigate } from "react-router-dom"

import "../css/BeerHome.css"
import bg from  "../pixelart/bar/BarBackground.png"
import hand from "../pixelart/bar/Hand.png"
import me from "../pixelart/bar/Me.png"
import lights from "../pixelart/bar/Lights.gif"
import lightfixtures from "../pixelart/bar/LightFixtures.png"
import draftbeer from "../pixelart/bar/DraftBeer.png"
import latestbeersign from "../pixelart/bar/LatestBeer.png"
import allbeersign from "../pixelart/bar/AllBeerSign.png"


const BeerPostPage = () => {

    const navigate = useNavigate()

    function redirectToBeerList() {
        navigate("/beerdir")
    }

    async function redirectToLatestBeer() {
        navigate("/beer/latest")
    }

    return (
        <div className="main_container">
            <img src={bg} className="background" alt="bar background"/>
            <img src={me} className="me" alt="me!"/>
            <img src={lights} className="lights" alt="light gifs"/>
            <img src={lightfixtures} className="lightfixtures" alt="light fixtures"/>
            <img src={draftbeer} className="draftbeer" alt="draftbeer" onClick={redirectToBeerList}/>
            <img src={hand} className="hand" alt="hand" onClick={redirectToLatestBeer}/>
            <img src={latestbeersign} className="latestbeer" alt="latestbeer sign"/>
            <img src={allbeersign} className="allbeer" alt="allbeer"/>
        </div>
    )
}

export default BeerPostPage;