import { Link } from "react-router-dom";

import "../view/css/AlbumCard.css"

const AlbumCard = ({obj, title, art, release_date, link}) => {
    return (
        <Link to={link} target="_blank" className="album_link_div">
            <div className="AlbumCard">
                <img src={art} alt="album art"></img><br/>
                <div className="title_div">{title}</div>
                <div className="release_info_div">{release_date}</div>
            </div>
        </Link>
    )
}

export default AlbumCard;