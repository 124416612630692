import wrinklykong from "../images/wrinklyghost.gif"
import "../css/MainPage.css"

const MainPage = () => {
    return(
        <div className="main_page_container">
            <div className="main_display_page">
                <img src={wrinklykong}/>
                <p className="p_title">
                    Wrinkly Kong Dot Com<br/>
                    dot dot dot ... kinddd of epic
                </p>
            </div>
        </div>
    );
}

export default MainPage;