import HeadBang from "../view/gifs/headbang.gif"

const LoadingFailed = () => {
    return (
        <div className="page_not_found">
            <div className="not_found_title">
                Error Fetching Data
                <p style={{"font-size": "2vh"}}>
                    Email this fool at johnwjones1337@gmail.com and tell him to fix his website!!
                </p>
            </div>
            <div className="headbanggif">
                <img src={HeadBang} alt="head banging!"/><br/>
            </div>
        </div>
    )
}

export default LoadingFailed;