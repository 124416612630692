import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { getAllBeers } from "../../services/beerServices";


const BeerTierListPage = ( props ) => {

    const [beers, setBeers] = useState();
    const wasCalled = useRef(false);
    const isLoading = useRef(true);

    const navigate = useNavigate()

    function redirectToPageNotFound() {
        navigate("/notfound")
    }

    function init() {
        loadBeers();
    }

    async function loadBeers() {
        const posts = await getAllBeers()
        if (posts == null) {
            redirectToPageNotFound()
            return
        }
        isLoading.current = false;
        setBeers(posts);
    }

    useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        init();
    }, [])

    if (isLoading.current) {
        return (
            <p>Loading...</p>
        )
    }
    else {
        // console.log(beers)
        return (
            <div>
                {Array.from(new Array(beers.data.length), (e1, index) => (
                    <p>{beers.data[index].rating}</p>
                ))}
            </div>
        )
    }

}

export default BeerTierListPage;