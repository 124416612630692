import Parser from 'html-react-parser';
import 'showdown'


const MDTestPage = () => {
    var test = 
`
# Beer Name | Company

This morning I woke up and decided it to be a pilsner day. I love me a good pilsner. I fuck my beer cans after use.

* Test yeah

# One

## One two three

## Hello?

`

    var showdown  = require('showdown'),
    converter = new showdown.Converter(),
    text      = test,
    html      = converter.makeHtml(text);
    return(
        <div> 
            {Parser(html) } 
        </div>
    );
}

export default MDTestPage;